<template>
  <div>
    <van-nav-bar title="申请" left-arrow @click-left="onClickLeft" />
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="productCode"
          name="产品编码"
          label="产品编码"
          placeholder="产品编码"
          disabled
        />
        <van-field
          v-model="suname"
          name="开卡人姓名"
          label="开卡人姓名"
          placeholder="开卡人姓名"
          :rules="[{ required: true, message: '请填写开卡人姓名' }]"
        />
        <van-field
          v-model="identityCard"
          name="身份证号"
          label="身份证号"
          placeholder="身份证号"
          :rules="[
            { required: true, message: '请填写身份证号' },
            {
              validator: this.validateIdentityCard,
              message: '身份证号格式不正确',
            },
          ]"
        />

        <van-field
          v-model="deliveryAddress"
          name="省市区"
          label="省市区"
          placeholder="省市区"
          :rules="[{ required: true, message: '请选择省市区' }]"
          @click="show = true"
          readonly
        />

        <van-field
          v-model="address"
          name="详细地址"
          label="详细地址"
          placeholder="详细地址"
          :rules="[{ required: true, message: '请填写详细地址' }]"
        />

        <van-field
          v-model="mobile"
          name="开卡人手机号"
          label="开卡人手机号"
          placeholder="开卡人手机号"
          :rules="[
            { required: true, message: '请填写开卡人手机号' },
            { validator: this.validateMobile, message: '手机号格式不正确' },
          ]"
        />
        <van-area :area-list="areaList" v-if="show" @confirm="onconfirm" />

        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            :loading="loading"
            native-type="submit"
            loading-text="提交中..."
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { applyAPI } from "@/api/index";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      productCode: "", // 产品编码
      suname: "", //开卡人姓名
      identityCard: "", //身份证号
      deliveryAddress: "", // 省份城市区县
      address: "", // 详细地址
      mobile: "", // 手机号
      code: "", // 地址栏的code
      //  地区
      show: false,
      areaList,
      loading: false,
    };
  },
  mounted() {
    this.getId();
  },
  methods: {
    // 拿到地址栏的id
    async getId() {
      const id = this.$route.query.id;
      const code = this.$route.query.code;
      this.productCode = id;
      this.code = code;
    },
    // 返回
    onClickLeft() {
      // 拼接上地址栏的 code
      const path = `/${this.code}`; // 动态拼接路径
      this.$router.push({ path });
    },

    // 效验身份证号
    validateIdentityCard(value) {
      const idCardRegex =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/;
      return idCardRegex.test(value);
    },
    // 效验手机号
    validateMobile(value) {
      const mobileRegex = /^1[3-9]\d{9}$/;
      return mobileRegex.test(value);
    },

    // 确定地址
    onconfirm(e) {
      this.deliveryAddress = e[0].name + "," + e[1].name + "," + e[2].name;
      this.show = false;
    },

    // 提交
    async onSubmit() {
      this.loading = true;
      try {
        const res = await applyAPI({
          productCode: this.productCode,
          suname: this.suname,
          identityCard: this.identityCard,
          deliveryAddress: this.deliveryAddress,
          address: this.address,
          mobile: this.mobile,
          code: this.code,
        });
        Toast(res.msg);
        Dialog.alert({
          title: "申请成功，请妥善保管订单号",
          message: res.data.order_id,
        }).then(() => {
          this.loading = false;
          // 复制订单号
          navigator.clipboard.writeText(res.data.order_id);
          // 清空数据
          this.suname = "";
          this.identityCard = "";
          this.deliveryAddress = "";
          this.address = "";
          this.mobile = "";
          this.code = "";
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>