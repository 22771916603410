var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{attrs:{"title":"申请","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"产品编码","label":"产品编码","placeholder":"产品编码","disabled":""},model:{value:(_vm.productCode),callback:function ($$v) {_vm.productCode=$$v},expression:"productCode"}}),_c('van-field',{attrs:{"name":"开卡人姓名","label":"开卡人姓名","placeholder":"开卡人姓名","rules":[{ required: true, message: '请填写开卡人姓名' }]},model:{value:(_vm.suname),callback:function ($$v) {_vm.suname=$$v},expression:"suname"}}),_c('van-field',{attrs:{"name":"身份证号","label":"身份证号","placeholder":"身份证号","rules":[
          { required: true, message: '请填写身份证号' },
          {
            validator: this.validateIdentityCard,
            message: '身份证号格式不正确',
          },
        ]},model:{value:(_vm.identityCard),callback:function ($$v) {_vm.identityCard=$$v},expression:"identityCard"}}),_c('van-field',{attrs:{"name":"省市区","label":"省市区","placeholder":"省市区","rules":[{ required: true, message: '请选择省市区' }],"readonly":""},on:{"click":function($event){_vm.show = true}},model:{value:(_vm.deliveryAddress),callback:function ($$v) {_vm.deliveryAddress=$$v},expression:"deliveryAddress"}}),_c('van-field',{attrs:{"name":"详细地址","label":"详细地址","placeholder":"详细地址","rules":[{ required: true, message: '请填写详细地址' }]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('van-field',{attrs:{"name":"开卡人手机号","label":"开卡人手机号","placeholder":"开卡人手机号","rules":[
          { required: true, message: '请填写开卡人手机号' },
          { validator: this.validateMobile, message: '手机号格式不正确' },
        ]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),(_vm.show)?_c('van-area',{attrs:{"area-list":_vm.areaList},on:{"confirm":_vm.onconfirm}}):_vm._e(),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","loading":_vm.loading,"native-type":"submit","loading-text":"提交中..."}},[_vm._v(" 提交 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }