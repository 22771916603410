import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../view/HomePage.vue'
import Detail from '../view/DetailPage.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/detail', component: Detail },
    { path: '/:id', component: Home },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router