<template>
  <div class="home_page">
    <van-nav-bar title="申请" />
    <van-search
      show-action
      v-model="order_id"
      placeholder="请输入订单号"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="card">
      <div class="card-row">
        <div
          class="card-item"
          v-for="(item, index) in cardList"
          :key="index"
          @click="handelima(item.productCode)"
        >
          <img :src="item.image" alt="" class="img" />
          <div class="card_id">ID:{{ item.productCode }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="tag_box">
            <div v-for="(tag, index) in item.tags.split(',')" :key="index">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCardListAPI, getOrderAPI } from "@/api/index";
import { Dialog } from "vant";
export default {
  data() {
    return {
      order_id: "", // 搜索
      cardList: [],
      urlcode: "", // 地址信息
    };
  },
  created() {
    console.log(this.$route);
    // 获取地址栏信息
    this.urlcode = this.$route.fullPath;
    // 去掉 /
    this.urlcode = this.urlcode.split("/")[1];
    this.getCardList();
  },
  methods: {
    // 查询订单
    async onSearch() {
      const res = await getOrderAPI({
        order_id: this.order_id,
      });
      console.log(res);
      Dialog.alert({
        title: "订单状态",
        message:
          res.data.message +
          "\n" +
          "手机号:" +
          res.data.buyMobile +
          "\n" +
          "快递公司:" +
          res.data.logisticsCompany +
          "\n" +
          "物流单号:" +
          res.data.logistics,
      }).then(() => {
        // on close
      });
    },
    // 获取手机卡列表
    async getCardList() {
      const res = await getCardListAPI({
        code: this.urlcode,
      });
      this.cardList = res.data;
    },
    // 跳转页面
    handelima(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
          code: this.urlcode,
        },
      });
    },
  },
};
</script>

<style>
.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.card .card-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.card .card-row .card-item {
  width: calc(50% - 10px);
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  /* 盒子阴影 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  /* transition: all 0.3s ease-in-out; */
}

.card .card-row .card-item .img {
  width: 100%;
  height: 205px;
  object-fit: cover;
}

.card .card-row .card-item .card_id {
  font-weight: bold;
  background-color: #505050;
  opacity: 0.7;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.card .card-row .card-item .title {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 5px 10px 5px;
}

.card .card-row .card-item .tag_box {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
}

.card .card-row .card-item .tag_box div {
  font-size: 12px;
  color: #999;
  padding: 5px;
  background-color: #f5f5f5;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  flex: 1;
  text-align: center;
}

.card .card-row .card-item .tag_box div:nth-child(2) {
  background-color: #926dde;
  color: #fff;
}
.card .card-row .card-item .tag_box div:nth-child(3) {
  background-color: #f96197;
  color: #fff;
  margin-right: 0;
}
/* 移动端适配 */
@media screen and (max-width: 768px) {
  .card .card-row .card-item {
    width: calc(50% - 10px); /* 保持每行两个卡片 */
  }

  .card .card-row .card-item .img {
    height: 180px; /* 减少图片高度 */
  }

  .card .card-row .card-item .title {
    font-size: 12px; /* 减小标题字体大小 */
  }

  .card .card-row .card-item .tag_box div {
    font-size: 10px; /* 减小标签字体大小 */
    padding: 3px; /* 减小内边距 */
  }
}
</style>