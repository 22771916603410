// 引入axios
import request from '@/utils/request'

// 查询订单
export const getOrderAPI = (params) => {
    return request({
        url: 'api/mobile/detail',
        method: 'post',
        params
    })
}

// 获取手机卡列表
export const getCardListAPI = (params) => {
    return request({
        url: 'api/mobile/h5index',
        method: 'post',
        params
    })
}
// 提交申请
export const applyAPI = (params) => {
    return request({
        url: 'api/mobile/store',
        method: 'post',
        params
    })
}