import axios from 'axios';

// 创建axios实例
const service = axios.create({
  // baseURL: 'http://192.168.1.6/', // api 的 base_url
  baseURL: 'https://fenfa.douhuanghou.cn/', // api 的 base_url
  timeout: 5000 // 请求超时时间
});

// 请求拦截
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // 添加请求头等操作
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);


// 响应拦截
service.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么
    // 统一处理响应数据等操作
    return response.data;
  },
  (error) => {
    // 对响应错误做些什么
    // 统一处理错误等操作
    return Promise.reject(error);
  }
);


export default service